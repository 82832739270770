<template>
	<div>
		<el-form :inline="true">
			<el-form-item>
				<el-button type="primary" @click="addOrUpdateHandle()">新增</el-button>
			</el-form-item>
		</el-form>
		<el-table :data="dataList" border style="width: 100%;" row-key="id" :tree-props="{children: 'children', hasChildren: 'hasChildren'}">>
			<el-table-column prop="name" header-align="center" align="center" width="120" label="名称"></el-table-column>
			<el-table-column prop="parentName" header-align="center" align="center" width="120" label="上级菜单"></el-table-column>
			<el-table-column header-align="center" align="center" label="图标">
				<template slot-scope="scope">
					<i :class="scope.row.icon || ''"></i>
				</template>
			</el-table-column>
			<el-table-column prop="type" header-align="center" align="center" label="类型">
				<template slot-scope="scope">
					<el-tag v-if="scope.row.type === 0" size="small">目录</el-tag>
					<el-tag v-else-if="scope.row.type === 1" size="small" type="success">菜单</el-tag>
					<el-tag v-else-if="scope.row.type === 2" size="small" type="info">按钮</el-tag>
					<el-tag v-else-if="scope.row.type === 3" size="small" type="info">接口</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="weight" header-align="center" align="center" label="排序号"></el-table-column>
			<el-table-column prop="url" header-align="center" align="center" width="150" :show-overflow-tooltip="true" label="菜单URL"></el-table-column>
			<el-table-column prop="perms" header-align="center" align="center" width="150" :show-overflow-tooltip="true" label="授权标识"></el-table-column>
			<el-table-column prop="navIndex" header-align="center" align="center" width="150" :show-overflow-tooltip="true" label="navIndex"></el-table-column>
			<el-table-column fixed="right" header-align="center" align="center" width="150" label="操作">
				<template slot-scope="scope">
					<el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">修改</el-button>
					<!-- <el-button type="text" size="small" @click="deleteHandle(scope.row.id)">删除</el-button> -->
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>
<script>
import { treeDataTranslate } from '@/utils';

export default {
	data () {
		return {
			dataList: []
		};
	},
	created () {
		this.menuList();
	},
	methods: {
		addOrUpdateHandle (id) {
			this.$root.go('/mnt/addMenus', { id });
		},
		menuList () {
			this.$root.request('menuList').then((data) => {
				if (data) {
					this.dataList = treeDataTranslate(data, 'id').sort((a, b) => a.weight - b.weight);
				}
			});
		}
	}
};
</script>