const S4 = exports.S4 = function () {
	return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
};
exports.generateGuid = function () {
	return S4() + S4() + S4() + S4() + S4() + S4() + S4() + S4();
};

exports.dateFormat = function (date, _fmt) {
	if (!date) {
		return '';
	}
	var thisDate = date;
	if (typeof date === 'string') {
		return date;
	}
	if (typeof date === 'number') {
		thisDate = new Date(date);
	}
	var o = {
		'M+': thisDate.getMonth() + 1, // 月份
		'd+': thisDate.getDate(), // 日
		'h+': thisDate.getHours(), // 小时
		'm+': thisDate.getMinutes(), // 分
		's+': thisDate.getSeconds(), // 秒
		'q+': Math.floor((thisDate.getMonth() + 3) / 3), // 季度
		S: thisDate.getMilliseconds() // 毫秒
	};
	var fmt = _fmt || 'yyyy-MM-dd';
	if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, String(thisDate.getFullYear()).substr(4 - RegExp.$1.length));
	for (var k in o) {
		if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(String(o[k]).length)));
	}
	return fmt;
};

/**
 * @param newVal 新的值
 * @param oldVal 旧的值
 * @param isNum 是否为 Number 类型
 * @param fn 操作函数
 */
exports.updateFieldValue = function (newVal, oldVal, isNum = false, fn) {
	let _newVal = newVal;
	if (isNum) {
		_newVal = Number(newVal);
	}
	if (_newVal !== oldVal) {
		fn(_newVal);
	}
};

/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
exports.treeDataTranslate = function (data, id = 'id', pid = 'parentId') {
	var res = [];
	var temp = {};
	for (var i = 0; i < data.length; i++) {
		temp[data[i][id]] = data[i];
	}
	for (var k = 0; k < data.length; k++) {
		if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
			if (!temp[data[k][pid]].children) {
				temp[data[k][pid]].children = [];
			}
			if (!temp[data[k][pid]]._level) {
				temp[data[k][pid]]._level = 1;
			}
			data[k]._level = temp[data[k][pid]]._level + 1;
			temp[data[k][pid]].children.push(data[k]);
		} else {
			res.push(data[k]);
		}
	}
	return res;
};

function getType (param) {
	var type = Object.prototype.toString.call(param);
	type = type.split(' ')[1].replace(']', '');
	return type;
}

exports.isArray = function (param) {
	return getType(param) === 'Array';
};

// 验证是否为手机号
exports.validatePhone = function (rule, value, callback) {
	const reg = /^[1][3,4,5,7,8][0-9]{9}$/;
	if (value === '' || value === undefined || value === null) {
		callback();
	} else {
		if ((!reg.test(value)) && value !== '') {
			callback(new Error('请输入正确的手机号码'));
		} else {
			callback();
		}
	}
};

// 验证是否为身份证号
exports.validateIdCard = function (rule, value, callback) {
	const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
	if (value === '' || value === undefined || value === null) {
		callback();
	} else {
		if ((!reg.test(value)) && value !== '') {
			callback(new Error('请输入正确的身份证号码'));
		} else {
			callback();
		}
	}
};