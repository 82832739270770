var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        { attrs: { inline: true } },
        [
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.addOrUpdateHandle()
                    }
                  }
                },
                [_vm._v("新增")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.dataList,
            border: "",
            "row-key": "id",
            "tree-props": { children: "children", hasChildren: "hasChildren" }
          }
        },
        [
          _vm._v(">\n\t\t"),
          _c("el-table-column", {
            attrs: {
              prop: "name",
              "header-align": "center",
              align: "center",
              width: "120",
              label: "名称"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "parentName",
              "header-align": "center",
              align: "center",
              width: "120",
              label: "上级菜单"
            }
          }),
          _c("el-table-column", {
            attrs: { "header-align": "center", align: "center", label: "图标" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("i", { class: scope.row.icon || "" })]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "type",
              "header-align": "center",
              align: "center",
              label: "类型"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.type === 0
                      ? _c("el-tag", { attrs: { size: "small" } }, [
                          _vm._v("目录")
                        ])
                      : scope.row.type === 1
                      ? _c(
                          "el-tag",
                          { attrs: { size: "small", type: "success" } },
                          [_vm._v("菜单")]
                        )
                      : scope.row.type === 2
                      ? _c(
                          "el-tag",
                          { attrs: { size: "small", type: "info" } },
                          [_vm._v("按钮")]
                        )
                      : scope.row.type === 3
                      ? _c(
                          "el-tag",
                          { attrs: { size: "small", type: "info" } },
                          [_vm._v("接口")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "weight",
              "header-align": "center",
              align: "center",
              label: "排序号"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "url",
              "header-align": "center",
              align: "center",
              width: "150",
              "show-overflow-tooltip": true,
              label: "菜单URL"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "perms",
              "header-align": "center",
              align: "center",
              width: "150",
              "show-overflow-tooltip": true,
              label: "授权标识"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "navIndex",
              "header-align": "center",
              align: "center",
              width: "150",
              "show-overflow-tooltip": true,
              label: "navIndex"
            }
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              "header-align": "center",
              align: "center",
              width: "150",
              label: "操作"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.addOrUpdateHandle(scope.row.id)
                          }
                        }
                      },
                      [_vm._v("修改")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }